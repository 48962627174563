import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import {
  billingAddressCompleted,
  cardCompleted,
  realNameCompleted,
  shippingAddressCompleted,
  getMissingFields,
} from "../utils/checkuser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCircleExclamation,
  faCross,
  faSchoolCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { auth, db } from "../config/firebaseSetup";

const FormCompletionContext = createContext(null);

const fieldGroups = {
  personalInfo: {
    title: "Personal Information",
    id: "personal-info",
    check: realNameCompleted,
    fields: [ "lastName", "firstName", "nickname" ],
  },
  billing: {
    id: "billing",
    title: "Billing Address",
    check: billingAddressCompleted,
    fields: [
      "addressBillTo",
      "billToAddress",
      "cityBillTo",
      "countryBillTo",
      "postal-codeBillTo",
      "state-provinceBillTo",
    ],
  },

  shipping: {
    id: "shipping",
    title: "Shipping Address",
    check: shippingAddressCompleted,
    fields: [
      "addressShipping",
      "cityShipping",
      "countryShipping",
      "postal-codeShipping",
      "state-provinceShipping",
    ],
  },

  payment: {
    id: "payment",
    title: "Payment Method",
    check: cardCompleted,
    fields: [ "paymentMethodAdded" ],
  },
};

export const FormCompletionProvider = ({ children }) => {
  // const [ user, setUser ] = useState({});
  // const [ showModal, setShowModal ] = useState(false);
  // const [ incompleteFields, setIncompleteFields ] = useState([]);

  const [ user, setUser ] = useState(null);
  const [ loading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);
  const [ showModal, setShowModal ] = useState(false);
  const [ incompleteFields, setIncompleteFields ] = useState([]);

  // useEffect(() => {
  //   checkCompletion();
  // }, [ user ]);

  useEffect(() => {
    let unsubscribeUser = null;

    const unsubscribeAuth = onAuthStateChanged(auth, async (authUser) => {
      if (!authUser) {
        setUser(null);
        setLoading(false);
        if (unsubscribeUser) {
          unsubscribeUser();
          unsubscribeUser = null;
        }
        return;
      }

      const userDocRef = doc(db, "users", authUser.uid);
      unsubscribeUser = onSnapshot(
        userDocRef,
        (doc) => {
          if (doc.exists()) {
            const userData = doc.data();
            setUser(userData);
            checkCompletion(userData);
          } else {
            setUser(null);
            setError("User document not found");
          }
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching user data:", error);
          setError(error.message);
          setLoading(false);
        }
      );
    });

    // Cleanup subscriptions on unmount
    return () => {
      unsubscribeAuth();
      if (unsubscribeUser) {
        unsubscribeUser();
      }
    };
  }, []);

  const checkCompletion = (userData) => {
    if (!userData) return;

    const missingFields = getMissingFields(userData);
    setIncompleteFields(missingFields);
    setShowModal(missingFields.length > 0);
  };

  // const updateUser = (newData) => {
  //   setUser(prev => ({ ...prev, ...newData }));
  // };

  const value = {
    user,
    showModal,
    setShowModal,
    incompleteFields,
    loading,
    error,
    fieldGroups,
  };

  // console.log(user);

  return (
    <FormCompletionContext.Provider value={value}>
      {children}
      <CompletionModal />
    </FormCompletionContext.Provider>
  );
};

export function useFormCompletion () {
  const context = useContext(FormCompletionContext);
  if (!context) {
    throw new Error(
      "useFormCompletion must be used within a FormCompletionProvider"
    );
  }
  return context;
}

const scrollToElement = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

    element.focus({ preventScroll: true });
    element.classList.add("highlight-section");
    setTimeout(() => {
      element.classList.remove("highlight-section");
    }, 2000);
  }
};

function CompletionModal () {
  const { showModal, user, incompleteFields } = useFormCompletion();

  const handleGroupClick = useCallback((groupId) => {
    scrollToElement(groupId);
  }, []);

  if (!showModal) return null;

  const getIncompleteGroups = () => {
    return Object.entries(fieldGroups).filter(
      ([ _, group ]) => !group.check(user)
    );
  };

  const formatFieldName = (field) => {
    return field
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .replace(/(BillTo|Shipping)$/, " $1");
  };

  return (
    <div className="fixedCm top-4Cm left-4Cm z-50Cm max-w-mdCm bg-whiteCm rounded-lgCm shadow-lgCm borderCm border-gray-200Cm p-4Cm animate-slideInCm">
      <div className="flexCm justify-betweenCm items-startCm">
        <div className="flexCm items-centerCm">
          <h3 className="text-mdCm font-semiboldCm">Complete</h3>
        </div>
        {/* <button 
          onClick={() => setShowModal(false)} 
          className="text-gray-400Cm hover:text-gray-500Cm"
        >
        </button> */}
      </div>

      <div className="mt-4Cm space-y-4Cm">
        {getIncompleteGroups().map(([ key, group ]) => (
          <div key={key} className="space-y-2Cm">
            <button
              onClick={() => handleGroupClick(group.id)}
              className="font-mediumCm  text-gray-700Cm flexCm items-centerCm formCompleteBtn"
            >
              {group.check(user) ? (
                <FontAwesomeIcon icon={faCheckCircle} />
              ) : (
                <FontAwesomeIcon icon={faCircleExclamation} />
              )}
              {group.title}
            </button>
            <ul className="pl-6Cm space-y-1Cm">
              {group.fields.map(
                (field) =>
                  incompleteFields.includes(field) && (
                    <li key={field} className="text-smCm text-gray-600Cm">
                      • {formatFieldName(field)}
                    </li>
                  )
              )}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}
