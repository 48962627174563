import { useEffect, useState } from "react";
import { auth, db } from "../../config/firebaseSetup";
import UserProfilePage from "../../pages/UserProfilePage";
import { useUser } from "../../context/UserContext";
import { doc, getDoc } from "firebase/firestore";
import Loading from "../../components/Loader";
import Button from "../../components/Button";
import transition from "../../utils/transition";
import { FormCompletionProvider } from "../../context/FormCompletionContext";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { showLoginModalHandler, logout } = useUser();

  const getUser = async (currentUser) => {
    try {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
          setUser(docSnap.data());
        } else {
          localStorage.removeItem("loginUser");
          logout();
          auth.signOut();
        }
      }
      setLoading(false);
    } catch (error) {
      localStorage.removeItem("loginUser");
      logout();
      auth.signOut();
      setLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      getUser(currentUser);
    });
    return () => unsubscribe();
  }, [logout]);

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return (
      <div className="login-if-no-user">
        <div>
          <h2>Please Log In</h2>
          <p>You need to be logged in to access your profile.</p>
        </div>
        <Button onClick={showLoginModalHandler}>LOG IN</Button>
      </div>
    );
  }

  return (
    <div>
      <FormCompletionProvider>
        <UserProfilePage user={user} getUser={getUser} />
      </FormCompletionProvider>
    </div>
  );
};

export default transition(Profile);
